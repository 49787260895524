const PLANS = {
  1: {
    name: 'A 個人',
    pretaxAmount: '¥120,000',
    tax: '¥12,000',
    totalAmount: '¥132,000',
    desc: 'Plan A 個人（年間）'
  },
  2: {
    name: 'B ベーシック',
    pretaxAmount: '¥600,000',
    tax: '¥60,000',
    totalAmount: '¥660,000',
    desc: 'Plan B ベーシック'
  },
  3: {
    name: 'C ビジネス',
    pretaxAmount: '¥3,600,000',
    tax: '¥360,000',
    totalAmount: '¥3,960,000',
    desc: 'Plan C ビジネス'
  },
  4: {
    name: 'D エンタープライズ',
    pretaxAmount: '¥5,000,000',
    tax: '¥500,000',
    totalAmount: '¥5,500,000',
    desc: 'Plan D エンタープライズ'
  },
  5: {
    name: 'A 個人',
    pretaxAmount: '¥12,000',
    tax: '¥1,200',
    totalAmount: '¥13,200',
    desc: 'Plan A 個人（月間）'
  }
}

const HTTP_STATUS = [
  {
    code: 403,
    message: '403 Forbidden'
  },
  {
    code: 404,
    message: '404 Not Found'
  },
  {
    code: 500,
    message: 'サーバーが過負荷状態かメンテナンス中です。しばらくしてから再試行してください。'
  },
  {
    code: 502,
    message: '502 Bad Gateway'
  },
  {
    code: 504,
    message: '504 Gateway Timeout'
  }
]

const WHITE_LIST = [
  '/',
  '/404.html',
  '/fee-plans.html',
  '/login.html',
  '/introduction.html',
  '/show-case.html',
  '/news.html',
  '/report.html'
]

const DASHBOARD_LIST = [
  '/dashboard.html',
  '/enterprise.html',
  '/orders.html',
  '/devices.html',
  '/invitation.html',
  '/payment.html'
]

const MAX_REQ_TIMEOUTS = {
  // max request timeout is 90s
  default: 90000,
  'auth/login': 10000,
  'webModels/list': 60000,
  'webModels/folders/tree': 60000,
  'portal/user/list': 60000
}

const PREFIX = 'SCANAT_'
const STORAGE_KEY = {
  LOGIN_TIME: PREFIX + 'LOGIN_TIME',
  REDIRECT_URL: PREFIX + 'REDIRECT_URL'
}

export { PLANS, HTTP_STATUS, WHITE_LIST, MAX_REQ_TIMEOUTS, STORAGE_KEY, DASHBOARD_LIST }
