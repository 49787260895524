import Toast from './toast'
import { checkAccountActive, isModelKey } from './utils'
import { STORAGE_KEY } from './consts'

const beforePage = () => {
  sessionStorage.removeItem(STORAGE_KEY.REDIRECT_URL)

  const userInfo = JSON.parse(sessionStorage.getItem('SCANAT_USER_INFO')) ?? {}
  console.log('beforePage', userInfo)

  if (Object.keys(userInfo).length === 0) {
    return
  }

  if (location.pathname.includes('models.html') && Object.keys(userInfo).length > 0) {
    let msg = ''

    if (!checkAccountActive()) {
      msg = 'アカウントが無効のため、Scanat Webを利用できません。'
      new Toast().toast('warning', msg, false, {
        callback: () => {
          window.location.href = '/landing.html'
        }
      })
    }

    if (!userInfo?.webPermission && !isModelKey()) {
      sessionStorage.setItem(STORAGE_KEY.REDIRECT_URL, '/landing.html')
    }
  }
}

export default beforePage
