import { isMobile, request } from './utils'
import Toast from './toast'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { PLANS } from './consts'

dayjs.extend(utc)
dayjs.extend(timezone)

let accountPlanId = 1

const renderPlan = () => {
  accountPlanId = location.search.split('=')[1] ?? '1'
  let plan = PLANS[accountPlanId]

  if (!plan) return

  const planName = document.querySelector('.plan-name')
  const pretaxAmount = document.querySelector('.plan-pre')
  const tax = document.querySelector('.plan-tax')
  const totalAmount = document.querySelector('.plan-total')

  planName.textContent = 'Plan ' + plan.name
  pretaxAmount.textContent = plan.pretaxAmount
  tax.textContent = plan.tax
  totalAmount.textContent = plan.totalAmount

  if (accountPlanId === '1') {
    const payWay = document.querySelector('.pay-way')
    payWay.classList.remove('d-none')

    payWay.addEventListener('input', _ => {
      const currentPayWay = payWay.querySelector('input:checked')
      const payWayRequestEl = document.querySelector('.pay-way-request')
      if (currentPayWay.value === 'monthly') {
        accountPlanId = '5'
        planName.textContent = 'Plan ' + plan.name

        payWayRequestEl.classList.add('d-none')
        const payMethodInput = document.querySelector('input[name="payMethod"]')
        payMethodInput.click()
      } else {
        accountPlanId = '1'
        planName.textContent = 'Plan ' + plan.name

        payWayRequestEl.classList.remove('d-none')
      }

      plan = PLANS[accountPlanId]
      pretaxAmount.textContent = plan.pretaxAmount
      tax.textContent = plan.tax
      totalAmount.textContent = plan.totalAmount
    })
  }
}

let ui = null
let fincode = null
let currentCardId = null

const btnAddCard = document.querySelector('.btn-add-card')
const btnViewList = document.querySelector('.btn-view-list')
const tipAddCardEl = document.querySelector('.tip-add-card')
const btnNewCard = document.querySelector('.btn-new-card')
const cardList = document.querySelector('.card-list')
const cardForm = document.querySelector('.card-form')
const submitBtn = document.querySelector('#submit')

const addNewCard = async callback => {
  btnAddCard.disabled = true
  submitBtn.disabled = true

  ui.getFormData()
    .then(data => {
      if (!data.cardNo) {
        new Toast().toast('warning', 'カード番号を入力してください', false, {
          callback: () => {
            btnAddCard.disabled = false
            submitBtn.disabled = false
          }
        })

        return
      }

      if (!data.expire) {
        new Toast().toast('warning', '有効期限を入力してください', false, {
          callback: () => {
            btnAddCard.disabled = false
            submitBtn.disabled = false
          }
        })

        return
      }

      fincode.tokens(
        {
          card_no: data.cardNo,
          expire: data.expire
        },
        function (status, response) {
          if (200 === status) {
            // リクエスト正常時の処理
            request('portal/trade/cards', { token: response.list[0].token }, true, 'POST').then(
              res => {
                if (res.code === 200 && res.data.status !== 500) {
                  if (callback) {
                    request('portal/trade/cards', null, true, 'GET').then(res => {
                      if (res.code === 200 && res.data) {
                        currentCardId = res.data[0].id
                        callback()
                      }
                    })
                    return
                  }

                  new Toast().toast('success', 'カードを登録しました', false, {
                    callback: () => {
                      btnViewList.classList.add('d-none')
                      tipAddCardEl.classList.add('d-none')
                      btnAddCard.classList.add('d-none')
                      btnAddCard.disabled = false
                      submitBtn.disabled = false
                      btnNewCard.classList.remove('d-none')

                      cardList.classList.remove('d-none')
                      cardForm.innerHTML = ''
                      renderCardList()
                    }
                  })
                } else {
                  btnAddCard.disabled = false
                  submitBtn.disabled = false
                  // failed to add credit card, please try again
                  new Toast().toast('danger', res.message, false, {
                    callback: () => {
                      if (res.code === 781) {
                        window.location.href = '/enterprise.html'
                      }
                    }
                  })
                }
              }
            )
          } else {
            // リクエストエラー時の処理
            new Toast().toast('danger', 'カード情報を入力してください')
            btnAddCard.disabled = false
            submitBtn.disabled = false
          }
        },
        function () {
          // 通信エラー処理
          new Toast().toast('danger', 'カード情報を入力してください')
          btnAddCard.disabled = false
          submitBtn.disabled = false
        }
      )
    })
    .catch(_ => {
      new Toast().toast('danger', 'カード情報を入力してください')
      btnNewCard.disabled = false
      submitBtn.disabled = false
    })
}

const renderCardList = async () => {
  if (!cardList) return

  cardList.innerHTML = `
    <table
      class="table align-middle"
      style="margin-top: 1rem; color: #16191e; font-size: 0.875rem;min-width: 880px;"
    >
      <thead style="background-color: #f5f7fa">
        <tr>
          <th scope='col' style='width: 1rem'></th>
          <th scope="col">クレジットカード番号</th>
          <th scope="col">有効期限</th>
          <th scope="col">最終更新日時</th>
        </tr>
      </thead>
      <tbody class="card-list-body"></tbody>
    </table>
  `

  const listRes = await request('portal/trade/cards', null, true, 'GET')
  if (listRes.code === 200 && listRes.data?.length === 0) {
    document.querySelector('.tip-add-card').textContent =
      '左側にクレジットカード情報を入力し、右側のボタンをクリックして支払いを行ってください。'

    btnNewCard.click()
    document.querySelector('.btn-add-card').classList.add('d-none')
    document.querySelector('.btn-view-list').classList.add('d-none')

    submitBtn?.addEventListener('click', async e => {
      e.preventDefault()
      submitBtn.disabled = true

      const payMethodInput = document.querySelector('input[name="payMethod"]:checked')
      if (payMethodInput.value === 'request') {
        await submitPayment()
        return
      }

      await addNewCard(async () => {
        await submitPayment()
      })
    })

    return
  }

  if (listRes.code === 200 && listRes.data) {
    listRes.data.forEach(item => {
      const tr = document.createElement('tr')
      tr.innerHTML = `
        <td>
         <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="listCurrentCard"
              id="${item.id}"
            />
          </div>
        </td>
        <td>${item.card_no}</td>
        <td>${item.expire.slice(0, 2) + '/' + item.expire.slice(2)}</td>
        <td>${dayjs
          .tz(item.created, 'Asia/Tokyo')
          .utc()
          .tz(dayjs.tz.guess())
          .format('YYYY-MM-DD HH:mm')}</td>
      `
      cardList.querySelector('.card-list-body').appendChild(tr)
    })
  }

  if (listRes.data.length === 5) {
    btnNewCard.classList.add('d-none')
  }

  submitBtn?.addEventListener('click', async e => {
    e.preventDefault()

    submitBtn.disabled = true
    await submitPayment()
  })

  document.querySelector('.credit-card-region').classList.remove('visually-hidden')
}

const preloadScripts = callback => {
  // append fincode scripts
  const isProd = process.env.ENV === 'prod'
  const host = isProd ? 'https://js.fincode.jp/' : 'https://js.test.fincode.jp/'
  const scriptUrls = ['v1/fincode.js', 'js/app.js', 'js/chunk-vendors.js', 'js/0.js', 'js/2.js']

  scriptUrls.forEach((url, index) => {
    if (index === 0) {
      const script = document.createElement('script')
      script.src = host + url
      document.querySelector('main').appendChild(script)
      script.onload = () => {
        callback()
      }
    } else {
      //  preload
      const obj = document.createElement('object')
      obj.data = host + url
      obj.type = 'text/javascript'
      obj.width = '0'
      obj.height = '0'
      document.head.appendChild(obj)
    }
  })
}

const renderCardForm = () => {
  btnNewCard.classList.remove('d-none')
  btnNewCard?.addEventListener('click', e => {
    e.preventDefault()

    if (!cardForm) return
    cardForm.innerHTML = `
      <form id="fincode-form" onsubmit="return false">
        <div id="fincode">
      </form>`

    fincode = Fincode(
      process.env.ENV === 'prod'
        ? 'p_prod_NmE5OTNlMjgtNTcyZC00ZTY2LThhZWYtYjViNWE4NjFjNDE4M2JmNWExODAtYzhhMi00OTE3LThkYjgtMDcxYzYzZjljNmUzc18yMjExMDI2MTQ5Mg'
        : 'p_test_NTRjOWY0MWUtOTU2MS00NzNiLTljMDUtYjVlZDBiMDc1ZTJkZGZkZjJlM2ItNmNjNS00Y2UzLThlYTYtZTQ0MjA2ZjM4ODUzc18yMjExMDI2MzY2Nw'
    )
    ui = fincode.ui({ layout: 'vertical' })
    ui.create('tokens', {
      layout: 'vertical',
      colorPlaceHolder: 'gray',
      expireMonth: 'MM',
      expireYear: 'YY'
    })
    ui.mount('fincode', isMobile() ? '250' : '350')

    const cardList = document.querySelector('.card-list')

    btnNewCard.classList.add('d-none')
    cardList.classList.add('d-none')
    document.querySelector('.credit-card-region').classList.remove('visually-hidden')

    cardForm.classList.remove('position-absolute')
    cardList.style.zIndex = 'initial'

    btnAddCard.classList.remove('d-none')
    btnAddCard.onclick = _ => {
      btnAddCard.disabled = true
      addNewCard()
    }

    btnViewList.classList.remove('d-none')
    tipAddCardEl.classList.remove('d-none')
    btnViewList.onclick = _ => {
      btnAddCard.classList.add('d-none')
      btnViewList.classList.add('d-none')
      tipAddCardEl.classList.add('d-none')
      btnNewCard.classList.remove('d-none')

      cardList.classList.remove('d-none')
      cardForm.innerHTML = ''
    }
  })
}

const submitPayment = async () => {
  const submitBtn = document.querySelector('#submit')
  submitBtn.disabled = true

  currentCardId =
    document.querySelector('input[name="listCurrentCard"]:checked')?.id ?? currentCardId
  const payMethodInput = document.querySelector('input[name="payMethod"]:checked')

  const paymentMethod = payMethodInput.value === 'request' ? 2 : 1

  if (!currentCardId && paymentMethod === 1) {
    new Toast().toast('warning', 'カードを選択してください', false, {
      callback: () => {
        submitBtn.disabled = false
      }
    })
    return
  }

  if (paymentMethod === 2) {
    const currentCustomerRes = await request('portal/customer', null, true, 'GET')
    if (currentCustomerRes?.data?.thirdPartyStatus === 2) {
      const statusModalEl = document.getElementById('statusModal')
      const companyEl = statusModalEl.querySelector('.company')
      companyEl.innerHTML = currentCustomerRes?.data?.customerName

      const myModal = new mdb.Modal(document.getElementById('statusModal'))
      myModal.show()

      submitBtn.disabled = false
      return
    }
  }

  request('portal/accountPlanOrder', {
    accountPlanId,
    paymentMethod,
    fincodeCardId: currentCardId
  })
    .then(res => {
      if (res.code === 200) {
        new Toast().toast('success', '注文が完了しました', false, {
          callback: () => {
            window.location.href = '/orders.html'
          }
        })
        return
      }

      if (res.code === 740) {
        // User's account plan is already expired
        // jump to dashboard
        new Toast().toast('danger', res.message, false, {
          callback: () => {
            window.location.href = '/dashboard.html'
          }
        })
        return
      }

      new Toast().toast('danger', res.message, false, {
        callback: () => {
          window.location.href = '/enterprise.html'
        }
      })
    })
    .catch(_ => {
      submitBtn.disabled = false
    })
}

window.onload = async () => {
  if (!location.pathname.includes('/payment.html')) return

  const currentCustomerRes = await request('portal/customer', null, true, 'GET')
  if (
    currentCustomerRes.code === 200 &&
    currentCustomerRes.data &&
    currentCustomerRes.data.myDeptType === 2
  ) {
    const dashboardBodyEl = document.querySelector('.dashboard-body')
    dashboardBodyEl.classList.add('d-none')
    document.querySelector('main').innerHTML = ''
    document.querySelector('header').innerHTML = ''

    const modalEl = document.getElementById('permissionModal')
    const modalBodyEl = modalEl.querySelector('.permission-modal-body')
    modalBodyEl.textContent = `現在、エージェントを通してパッケージを購入されていますが、関連事項については${
      currentCustomerRes.data?.agentName ?? ''
    }にお問い合わせください。`

    const myModal = new mdb.Modal(document.getElementById('permissionModal'))
    myModal.show()

    modalEl.addEventListener('hidden.mdb.modal', e => {
      window.location.href = '/dashboard.html'
    })
    return
  }

  preloadScripts(async () => {
    renderCardForm()
    await renderCardList()
  })

  renderPlan()

  const payMethodRadios = document.querySelector('.pay-method-radios')
  payMethodRadios?.addEventListener('click', e => {
    const value = e.target.value

    if (value === 'request') {
      document.querySelector('.credit-card-region').classList.add('d-none')
      document.querySelector('.request-book-region').classList.remove('d-none')

      return
    }

    if (value === 'credit-card') {
      document.querySelector('.credit-card-region').classList.remove('d-none')
      document.querySelector('.request-book-region').classList.add('d-none')
    }
  })
}
