import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyCyL1zcVcb6yPCGmrW1ssd3ZhMiytwVEsI',
  authDomain: 'scanat.firebaseapp.com',
  projectId: 'scanat',
  storageBucket: 'scanat.appspot.com',
  messagingSenderId: '499135754420',
  appId: '1:499135754420:web:8feb0c1a90bf9a2c72e7e1',
  measurementId: 'G-6HD8WM2L7F'
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

// Click events
const clickClasses = [
  'nav-home',
  'nav-intro',
  'nav-case',
  'nav-news',
  'nav-fee',
  'faq-tabs',
  'fee-pay-a',
  'fee-pay-b',
  'fee-pay-c',
  'fee-pay-consult',
  'home-login',
  'home-register',
  'view-report'
]

const hoverClasses = ['fee-card-a', 'fee-card-b', 'fee-card-c', 'fee-card-d']

window.addEventListener('load', () => {
  logEvent(analytics, 'lp_view_page')

  clickClasses.forEach(clickClass => {
    document.querySelector(`.${clickClass}`)?.addEventListener('click', () => {
      logEvent(analytics, `lp_${clickClass}`)
    })
  })

  const throttle = (fn, wait) => {
    let pre = 0
    return () => {
      const now = +new Date()
      if (now - pre > wait) {
        fn()
        pre = now
      }
    }
  }

  // Hover events, mouseover need be throttled
  hoverClasses.forEach(hoverClass => {
    document.querySelector(`.${hoverClass}`)?.addEventListener(
      'mouseover',
      throttle(() => {
        logEvent(analytics, `lp_${hoverClass}`)
      }, 2000)
    )
  })
})
